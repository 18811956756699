
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Pageniation as Table, TableRowsSelect } from "@/utils/decorator";
import StandardFlag from "@/components/scope/standard-flag.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";

const base = namespace("base");
const marketing = namespace("marketing");

@Component({
  computed: {
    dayjs() {
      return dayjs
    }
  },
  components: { SwitchStatus, StandardFlag }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("marketing.please-select-card-template"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class TestingItemList extends Vue {
  @marketing.Action queryCardTemplateList;
  @marketing.Action updateCardTemplateStatus;
  @base.Action getDictionaryList;
  @marketing.Action editIsShow;
  searchForm = {
    cardTemplateName: "",
    cardType: ""
  };
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  cardTemplateList: any[] = [];
  get searchList() {
    return [

      {
        label: "卡模版编号",
        type: "input",
        value: "",
        prop: "cardTemplateCode"
      },
      {
        label: "marketing.card-name",
        type: "input",
        value: "",
        prop: "cardTemplateName"
      },

      {
        label: "marketing.card-type",
        type: "select",
        value: "",
        selectList: this.cardTemplateTypeList,
        prop: "cardType"
      }
    ];
  }
  cardTemplateTypeList: any = [];
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  @TableRowsSelect.updateMethod
  confirmUpdateStatus(status, selectedRow) {
    return this.updateCardTemplateStatus({
      enableStatus: status,
      cardTemplateCodeList: selectedRow
        ? [selectedRow.cardTemplateCode]
        : this.mix_selectedRows.map(item => item.cardTemplateCode)
    }).then(data => {
      this.$message.success(
        status ? (this.$t("setting.enable-successful") as string) : (this.$t("setting.disable-success") as string)
      );
      this.loadListData();
    });
  }

  @TableRowsSelect.updateMethod
  confirmUpdateIsShow(isShow, selectedRow){
    this.editIsShow({
      cardTemplateCode: selectedRow.cardTemplateCode,
      isShow: isShow
    }).then(res=>{
      this.$message.success(
        isShow ? (this.$t("setting.enable-successful") as string) : (this.$t("setting.disable-success") as string)
      );
      this.loadListData();
    })
  }
  created() {
    this.init();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCardTemplateList({
      ...this.searchForm,
      orderItems: this.mix_sortParams,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize,
      status: "9"
    }).then(data => {
      this.cardTemplateList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.getDictionaryList("card_type").then(data => {
      this.cardTemplateTypeList = data;
    });
  }
  handleEdit(row) {
    this.$router.push(`/management/card-template-edit/${row.cardTemplateCode}`);
  }
  handleView(row) {
    this.$router.push(`/management/card-template-view/${row.cardTemplateCode}`);
  }

  handleCommand(command) {
    switch (command) {
      case "group":
        this.$router.push("/management/card-template-add");
        break;
      case "valueCard":
        console.log("[ command]", command);
        this.$router.push("/marketing/value-card-add");
        break;
      default:
        console.log("[ command]", command);
    }
  }
}
